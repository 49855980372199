@media screen and (max-width: 689px) {
    .big-container {
        padding-left: 16px;
        padding-right: 16px;
    }

    #projects {
        .section__fullheight {
            flex-direction: column;
            justify-content: center;
        }

        .section__content {
            width: 75%;
            min-width: 272px;
            padding: 0 0 0;
        }

        .section__image {
            margin-left: -56px;
            margin-right: -56px;
        }
    }

    .hidden-sm {
        display: none !important;
    }
}

@media screen and (max-width: 439px) {
    // #project__artinmov__frame {
    //   width: 50%;
    // }

    // #hello h1 {
    // 	font-size: 2em;
    // }

    // .project {
    //     h3 {
    //         font-size: 2.4rem;
    //     }

    //     .btn-container {
    //         margin-top: 1.25rem;
    //     }
    // }
}
